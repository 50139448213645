@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');

html {
  font-family: sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin: 0;
  color:#8493a5;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
  overflow-y: auto;
}

body:after, body:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: url(/images/background.jpg) center/cover no-repeat;
}

header {
  flex: 1;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

header a.logo {
  display: inline-block;
}
header .logo img {
  width: 143px;
}

section {
  margin: 100px 0 200px 0;
  background: rgba(255, 255, 255, 0.7)
}

.content {
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  margin: 0 auto;
}

article {
  flex: 1;
  padding: 4em 10em 4em 0;
}

article p {
  font: 700 20px/1.5em 'Roboto';
  color: #000;
}

p + p {
  margin-top: 20px;
}

p a {
  color: inherit;
  text-decoration: underline;
}
p a:hover {
  text-decoration: none;
}

.kkbb a {
  display: block;
  border: 3px solid #000;
  margin: 20px 0 0;
  padding: 10px;
  border-radius: 2px;
  background: #fff;
  color: #000;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  transition: all 300ms;
}
.kkbb img {
  vertical-align: middle;
}

@media (min-width: 370px) and (max-width: 767px) {
  header {
    text-align: center;
  }

  section {
    margin: 0 0 20px 0;
    padding: 40px 20px;
  }
  .content {
    flex-direction: column;
  }
  article {
    padding: 0;
  }

  article p {
    font-size: 16px;
  }
}
